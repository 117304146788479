import React, { useState, useEffect } from 'react';
import ButtonBuilder from './SharedParts/ButtonBuilder';
import { Outlet, Link } from "react-router-dom";
function Projects(props) {

  useEffect(() => { document.title = props.title }, [])

  var ProjectList = [
    //Example Project data
    /*
    {
      Id:2,
      Title: 'Gravity Frenzy',
      Description: `Gravity Frenzy is a tap centric block-breaking game. Gravity can change periodically causing the blocks to fall in a different direction. You need to clear the blocks while you can to get the high score.`,
      ImgUrl: require('../img/gravityFrenzyFaceIcon.png'),
      ImgOnClickLink: 'https://play.google.com/store/apps/details?id=com.rhumble.gravityFrenzy',
      CustomHtmlElement: ` 
      <a href="https://play.google.com/store/apps/details?id=com.rhumble.gravityFrenzy">
        <img
          alt="Get it on Google Play"
          src="https://developer.android.com/images/brand/en_generic_rgb_wo_45.png"
        />
      </a>`,
      buttonBuilderConfig : { type:"", linkUrl:"https://www.google.com", imgSrc:"https://knowpathology.com.au/app/uploads/2018/07/Happy-Test-Screen-01-825x510.png", linkText:"GoogleTest"},
      privacyLink: '/Privacy/GravityFrenzy'
    },
    */
    {
      Id: 4,
      Title: "Gravity Frenzy",
      Description: `Gravity Frenzy is a tap centric block-breaking game. Gravity can change periodically causing the blocks to fall in a different direction. You need to clear the blocks while you can to get the high score.`,
      ImgUrl: require("../img/gravityFrenzyFaceIcon.png"),
      ImgOnClickLink:
        "https://play.google.com/store/apps/details?id=com.rhumble.gravityFrenzy",
      buttonBuilderConfig: {
        type: "playStore",
        linkUrl:
          "https://play.google.com/store/apps/details?id=com.rhumble.gravityFrenzy",
      },
      privacyLink: "/privacy/GravityFrenzy",
    },
    {
      Id: 3,
      Title: "SecretScrambler",
      Description: `SecretScrambler is a text editor utility with built in security features.  After adding text, you can choose from multiple encryption types including AES and Triple DES.  SecretScrambler gives you the option to layer different types of encryption on top of eachother for added protection.`,
      ImgUrl: require("../img/SecretScramblerIconBorder.png"),
      ImgOnClickLink: "https://robhumble.github.io/SecretScrambler/",
      buttonBuilderConfig: {
        type: "gitHub",
        linkUrl: "https://robhumble.github.io/SecretScrambler/",
        linkText: "GitHub Page",
      },
      privacyLink: null,
    },
    {
      Id: 2,
      Title: "UltiSnake",
      Description: `UltiSnake is my first public Android game available on Google Play. After enjoying so much time with classic game that inspired UltiSnake, I decided create a new game that featured similar core concepts and expand the gameplay to include new features.`,
      ImgUrl: require("../img/ultisnake.png"),
      ImgOnClickLink:
        "https://play.google.com/store/apps/details?id=com.me.snake",
      buttonBuilderConfig: {
        type: "playStore",
        linkUrl:
          "https://play.google.com/store/apps/details?id=com.me.snake",
      },
      privacyLink: "/privacypolicy/UltiSnake",
    },
    {
      Id: 1,
      Title: "Payment Notifier",
      Description: `Payment Notifier is an app designed for roommates (or really anyone) who needs a quick way to split up a bill and notify others.
            Payment Notifier was my first experiment with Android development. I developed this simple utility as a quick way to split up monthly bills with my roommates and text them the amount they owed.`,
      ImgUrl: require("../img/paymentnotifier.png"),
      ImgOnClickLink: "https://github.com/robhumble/payment-notifier",
      buttonBuilderConfig: {
        type: "gitHub",
        linkUrl: "https://github.com/robhumble/payment-notifier",
        linkText: "GitHub Repo",
      },
      privacyLink: null,
    },
  ]

  var projectDisplay = [];

  ProjectList.forEach(project => {
    projectDisplay.push(

      <div className="row" align-v="center" key={"project" + project.Id}>
        <div className="col-12 col-lg-3 ">
          <div className="project-img-container">
            <a href={project.ImgOnClickLink}>
              <img src={project.ImgUrl} className="img-responsive center-block project-img" />
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-9 ">
          <h2>{project.Title}</h2>
          <p>
            <span dangerouslySetInnerHTML={{ __html: project.Description }} />
          </p>
          
          {project.privacyLink &&
            <p >
              <span>
                <Link to={project.privacyLink} >Privacy Policy</Link>
              </span>
            </p>
          }

          {project.buttonBuilderConfig &&
            <ButtonBuilder
              type={project.buttonBuilderConfig.type}
              linkUrl={project.buttonBuilderConfig.linkUrl}
              imgSrc={project.buttonBuilderConfig.imgSrc}
              linkText={project.buttonBuilderConfig.linkText} />
          }

          <span dangerouslySetInnerHTML={{ __html: project.CustomHtmlElement }} />
          <hr />
        </div>
      </div>

    )
  });


  return (
    <div className="main-content-padding-top main-content-padding-x">
      <div className="container">
        {projectDisplay}
      </div>
    </div>

  );
}
export default Projects;