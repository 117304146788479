import { Outlet, Link } from "react-router-dom";
function ButtonBuilder(props) {

    if (props.type == 'gitHub') {
        return (
            <div className="MainContentPadding">
                <p>
                    <a type="button" className="btn btn-outline-dark" variant="outline-secondary" href={props.linkUrl}><img src={require('../../img/GitHub-Mark-32px.png')} /> <b>{props.linkText}</b></a>
                </p>
            </div>
        );
    }
    else if (props.type == 'playStore') {
        return (
            <div className="MainContentPadding">
                <p>
                    <a href={props.linkUrl}>
                        <img
                            alt="Get it on Google Play"
                            src="https://developer.android.com/images/brand/en_generic_rgb_wo_45.png"
                        />
                    </a>
                </p>
            </div>
        );
    }
    else if (props.linkUrl[0] === '/') {
        return (
            <div className="MainContentPadding">
                <p>
                    <Link type="button" className="btn btn-outline-dark" variant="outline-secondary" to={props.linkUrl}><img className="button-builder-img" src={props.imgSrc} /> <b>{props.linkText}</b></Link>
                </p>
            </div>
        );
    }
    else {
        return (
            <div className="MainContentPadding">
                <p>
                    <a type="button" className="btn btn-outline-dark" variant="outline-secondary" href={props.linkUrl}><img className="button-builder-img" src={props.imgSrc} /> <b>{props.linkText}</b></a>
                </p>
            </div>
        );
    }

}
export default ButtonBuilder;