import React, { useState, useEffect } from 'react';
import ButtonBuilder from './SharedParts/ButtonBuilder';
function Contact(props) {

  useEffect(() => { document.title = props.title }, [])

  return (
    <div className="main-content-padding-top main-content-padding-x">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Looking to contact me?</h2>
            <h5>Try These:</h5>
            <p>
              <a href="mailto:robertshumble@gmail.com">robertshumble@gmail.com</a>
            </p>
            <p>
              <a href="http://www.linkedin.com/pub/robert-humble/45/734/40b">
                <img
                  src="https://static.licdn.com/scds/common/u/img/webpromo/btn_viewmy_160x33.png"
                  width="160"
                  height="33"
                  border="0"
                  alt="View Robert Humble's profile on LinkedIn"
                />
              </a>
            </p>
            <ButtonBuilder
              type="gitHub"
              linkUrl="https://github.com/robhumble"
              linkText="GitHub Profile"
            ></ButtonBuilder>
            <br />
            <br />
            <br />
            <h2>Looking for application support?</h2>
            <p>
              <a href="mailto:humbot1@gmail.com">humbot1@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;