import React, { useState, useEffect } from 'react';
function News(props) {

  useEffect(() => { document.title = props.title }, [])

  //News Posts
  var NewsList = [
    //Example News data
    /*
    {
      Id: 3,
      Date: '4/29/2020',
      Title: 'BIG TEST',
      Text: `TEST TEST TEST`
    },*/
    {
      Id: 6,
      Date: "9/20/2020",
      Title: "Experiments Section added",
      Text: ` 
        I've added a new section to the website to list all of my "experimental" projects.  These are projects that I'm either actively working on and exploring, or small one-off projects.  
        I think going forward most projects will start in this list and then I'll promote them to the main projects page when they are more built out.  The first of these is "Social Game", 
        a game I'm actively working on.  I should have more to announce on "Social Game" in the near future.
        `,
    },
    {
      Id: 5,
      Date: "6/27/2020",
      Title: "SecretScrambler Released",
      Text: ` 
        My goal for the past couple of months has been to clean up and release several of the side projects I’ve worked on over the last few years.  
        Continuing that trend, this week I’m releasing SecretScrambler.  SecretScrambler is a text editor/note-taking utility with built-in encryption features.  
        Going forward, SecretScrambler will be an open-source project.  Please take a look at the Projects section for more information and a link to its GitHub page. 
        Also, following up on my last post, I’ve added links for Payment Notifier's GitHub repo to the project page.         
        `,
    },
    {
      Id: 4,
      Date: "6/21/2020",
      Title: "Gravity Frenzy Released",
      Text: ` 
        This week I was able to release my latest project “Gravity Frenzy” to the Google Play store.  
        Gravity Frenzy is a fast paced block-breaking game with mechanics that involve shifting gravity, please look at the projects section for more information.
        In other news, I’ve decided to take Payment Notifier off of the Play Store and move it to open source.  When I make the GitHub repository public, I will add links to the project section here.          
        `,
    },
    {
      Id: 3,
      Date: "5/30/2020",
      Title: "Updated Site and General News",
      Text: ` 
        It has been quite a while since my last update. Since my last post here there have been quite a few changes in my life including changing jobs and moving to a different city.
        I've kept busy though, it may not look like it but I've completely re-built the site with Vue.js ( though i've kept the previous style/design choices ).  
        The changes I've made should allow me to update the site with news and content more easily going forward.
        In my spare time i've also been working on a few different projects as well.  I plan to release or announce more information about these projects in the near future, so check back soon.
        `,
    },
    {
      Id: 2,
      Date: "4/26/2015",
      Title: "New Updates",
      Text: ` 
        I&apos;ve rewritten and posted a new version Payment Notifier to Google Play. This is still a simple app like before, but I&apos;ve fixed some bugs I had noticed and added the option to add a description to the message. I&apos;ve also updated a few things around the site.
        I should have some more projects to anounce soon, and I&apos;ll update this page as soon as I have more news.`,
    },
    {
      Id: 1,
      Date: "1/28/2015",
      Title: "First Post !",
      Text: `With this post, the site officially launches.`,
    },
  ]

  var newsDisplay = [];

  NewsList.forEach(e => {
    newsDisplay.push(
      <div className="row" key={"news" + e.Id}>
        <div className="news-main-text-block">
          <h6>{e.Date}</h6>
          <h2>{e.Title}</h2>
          <p>
            <span dangerouslySetInnerHTML={{ __html: e.Text }} />
          </p>
        </div>
        <hr />
      </div>)
  });

  return (
    <div className="main-content-padding-top main-content-padding-x ">
      <div className="container">
        {newsDisplay}
      </div>
    </div>
  );
}
export default News;