import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

function Home(props) {

  var path
  const nav = useNavigate();
  const goToPath = useCallback((path) => nav(path, { replace: true }), [nav]);

  useEffect(() => { document.title = props.title }, [])

  function goToProjects() {
    goToPath('/projects');
  }

  return (
    <div className="main-content-padding-top ">

      <div className="container">
        <div className="row">
          <div className="col">

            <div id="homeCarousel" className="carousel slide" data-bs-ride="carousel" >
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#homeCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#homeCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#homeCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="2000" >
                  <img
                    className="d-block w-100"
                    src={require("../img/GravityRushTitleAnnounceSmall.jpg")}
                    alt="image slot"
                    onClick={() => goToProjects()}
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Gravity Frenzy</h5>
                    <p>Gravity Frenzy now available on the Google Play store!</p>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={require("../img/SecretScramblerAnnouncementOrange.jpg")}
                    alt="image slot"
                    onClick={() => goToProjects()}
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>SecretScrambler</h5>
                    <p>SecretScrambler now available on GitHub!</p>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    className="d-block w-100"
                    src={require("../img/AnnouncementUltiSnake.jpg")}
                    alt="image slot"
                    onClick={() => goToProjects()}
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>UltiSnake</h5>
                    <p>UltiSnake available now.</p>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#homeCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#homeCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;