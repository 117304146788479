import './assets/custom.scss'
import "./assets/custom-vars.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  function copyrightYear() {
    var curDate = new Date();
    return curDate.getFullYear();
  }

  return (
    <>
      <div className="container" id="MainNav">
        <div className="row">
          <div className="col col-3">
            <Link to="/">
              <img
                src={require('./img/humbledevgreen-sm.jpg')}
                className="img-responsive center-block humble-dev-logo" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">


            <nav className="navbar navbar-expand-lg bg-body-tertiary humble-navbar">
              <div className="container-fluid">
                <Link to="/"><i className="bi bi-house text-white"></i></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link to="/projects" className="nav-link">Projects</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/experiments" className="nav-link">Experiments</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/news" className="nav-link">News</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className="nav-link">About</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <Outlet />

      <div className="spacerForFooter"></div>
      <footer className="footer">
        <div id="copyRight" className="copyRight">
          <p className="text-muted">Copyright {copyrightYear()} Robert Humble All Rights Reserved.</p>
        </div>
      </footer>
    </>
  )
};

export default Layout;