import React, { useState, useEffect } from 'react';
import ButtonBuilder from './SharedParts/ButtonBuilder';

function Experiments(props) {

  useEffect(() => { document.title = props.title }, [])

  var experimentList = [
    {
      id: 2,
      name: "Social Game Party",
      desc:
        "A multiplayer social game.  This is a work-in-progress web-based multiplayer game platform.",
      link: "https://socialgameparty.web.app/#/",
    },
    {
      id: 1,
      name: "Martial Arts Practice Generator",
      desc:
        "A simple tool to build a practice schedule for the day. It generates a list of randomly selected exercises and forms.",
      link: "/practicegenerator",
    },
  ]

  var experimentsDisplay = [];
  experimentList.forEach(exp => {
    experimentsDisplay.push(

      <div className="row" key={"exp" + exp.id}>
        <div className="col col-sm=12 col-md-4">
          <h4>{exp.name}</h4>
        </div>
        <div className="col col-sm-12 col-md-4">
          <p>{exp.desc}</p>
        </div>
        <div className="col col-sm-12 col-md-4">
          <ButtonBuilder linkUrl={exp.link} linkText="Try it out!"></ButtonBuilder>
        </div>
      </div>
    )
  });

  return (
    <div className="main-content-padding-top main-content-padding-x">
      <div className="container">
        <p>This section is a collection of various other projects that aren't quite ready for prime-time. The experiments found here may be a work-in-progress that will be released in the future, or a one-off tool that won't see another update.</p>
      </div>
      <div className="container experiment-tbl">
        <div className="row d-none d-md-flex d-lg-flex">
          <div className="col col-4">
            <h2>Name</h2>
          </div>
          <div className="col col-4">
            <h2>Description</h2>
          </div>
          <div className="col col-4">
            <h2>Link</h2>
          </div>
        </div>
        <hr />
        {experimentsDisplay}
      </div>
    </div>
  );
}
export default Experiments;