import logo from './logo.svg';
import './assets/custom.scss'
import "./assets/custom-vars.scss";

import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";

import About from './components/About.js'
import ExampleComponent from './components/ExampleComponent.js'
import Contact from './components/Contact';
import News from './components/News';
import Projects from './components/Projects'
import Experiments from './components/Experiments';
import Home from './components/Home';
import PracticeGenerator from './components/PracticeGenerator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import Layout from './Layout';
import PrivacyGravityFrenzy from './components/PrivacyPolicy/privacyGravityFrenzy';
import PrivacyUltiSnake from './components/PrivacyPolicy/privacyUltiSnake';


function App() {
  return (
    <div className="App app-size">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home title="Home" />} />
            <Route path="projects" element={<Projects title="Projects" />} />
            <Route path="experiments" element={<Experiments title="Experiments" />} />
            <Route path="news" element={<News title="News" />} />
            <Route path="about" element={<About title="About" />} />
            <Route path="contact" element={<Contact title="Contact" />} />
            <Route path="PracticeGenerator" element={<PracticeGenerator title="Practice Generator" />} />
            <Route path="privacy/GravityFrenzy" element={<PrivacyGravityFrenzy title="Privacy Policy - Gravity Frenzy" />} />
            <Route path="privacypolicy/UltiSnake" element={<PrivacyUltiSnake title="Privacy Policy - UltiSnake" />} />
            <Route path="*" element={<Home title="Home" />} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
