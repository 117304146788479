import React, { useState, useEffect } from 'react';
function About(props) {

  useEffect(() => { document.title = props.title }, [])

  return (
    <div className="main-content-padding-top main-content-padding-x">

      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="float-left">About The Site:</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>This is a place I intend to use as a hub for information about myself and my projects past, present, and future. All of my public projects will be listed here with descriptions, links, and support information (if needed). In the future I may use this as a launchpad for web-based apps and games.</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="float-left">About Me:</h2>
          </div>
        </div>
        <div className="row">
          <div className="col col-12 col-xl-3">
            <img src={require('../img/rhumble-min.jpg')} className="about-img" />
          </div>
          <div className="col col-12 col-xl-9">
            <h3>Rob Humble</h3>
            <p>
              I am a software engineer from Texas.
              Currently, I work building cloud-based enterprise applications. I have a passion for software and technology in general, and love building new things. I am a big fan of video games, technology, martial arts, music... I have several interests, so there's a good chance I might start up a new project some time soon.
            </p>
          </div>
        </div>
      </div>

    </div>
  );
}
export default About;